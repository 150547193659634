<template>
	<div class="register_page">
		<loginRegistration pageType="register">
			<div class="flex">
				<div class="slogan_tit_1">密码重置</div>
			</div>
			<div class="register_form">
				<a-input
					placeholder="请输入手机号"
					v-model="phone"
					type="tel" />
				<div class="flex margin_b_44">
					<a-input
						placeholder="验证码"
						v-model="verify"
						type="number" />
					<div class="button" @click="getCode" v-if="!isGetCode">
						{{ btnText }}
					</div>
					<div class="button_disabled" v-else>
						{{ downTime }}秒后重新获取
					</div>
				</div>
				<a-input-password
					class="margin_b_44"
					placeholder="请输入密码（6位数字）"
					:max-length="6"
					v-model="password" />
				<a-input-password
					placeholder="请再次输入密码（6位数字）"
					:max-length="6"
					v-model="confirmPassword" />
				<div class="submit_btn" @click="goRegister">重置密码</div>
			</div>

			<div class="tips_box">
				<span class="tips_2" @click="goLogin">去登录</span>
			</div>
		</loginRegistration>
	</div>
</template>

<script>
import loginRegistration from '../../components/loginRegistration'
import { getVerifyCode, resetPassword } from '@/api/Official'
import { message } from 'ant-design-vue'

export default {
	components: { loginRegistration },
	data() {
		return {
			phone: '',
			password: '',
			confirmPassword: '',
			verify: '',
			downTime: 59,
			isGetCode: false, // 是否已经获取了验证码
			btnText: '获取验证码'
		}
	},
	methods: {
		goLogin() {
			this.$router.push({ name: 'OfficialLogin' })
		},
		getCode() {
			if (this.isGetCode) {
				return
			}
			const phoneReg = /^1[3456789]\d{9}$/
			if (!this.phone) {
				this.$message.warning('请输入11位手机号！')
				return
			} else if (!phoneReg.test(this.phone)) {
				this.$message.warning('请输入正确的手机号！')
				return
			}
			this.getCodeAsync(this.phone)
		},
		getCodeAsync(phone = '') {
			let downTime = 59
			this.isGetCode = true
			const intervalID = setInterval(() => {
				downTime--
				if (downTime <= 0) {
					clearInterval(intervalID)
					this.isGetCode = false
					this.btnText = '重新获取'
					this.downTime = 59
				} else {
					this.downTime = downTime
				}
			}, 1000)
			getVerifyCode({ phone, type: 2 }).then(res => {
				if (res.code !== 0) {
					message.error(res?.msg || '验证码发送失败，请稍后再试')
				}
			})
		},
		goRegister() {
			const phoneReg = /^1[3456789]\d{9}$/
			if (!this.phone) {
				this.$message.warning('请输入11位手机号！')
			} else if (!phoneReg.test(this.phone)) {
				this.$message.warning('请输入正确的手机号！')
			} else if (!this.verify || this.verify.length !== 4) {
				this.$message.warning('请输入4位验证码！')
			} else if (!this.password) {
				this.$message.warning('请输入密码！')
			} else if (!this.confirmPassword) {
				this.$message.warning('请再次输入密码！')
			} else if (
				this.password &&
				this.confirmPassword &&
				this.password !== this.confirmPassword
			) {
				this.$message.warning('两次密码输入不一致')
			} else {
				const params = {
					phone: this.phone.trim(),
					password: this.password.trim(),
					re_password: this.confirmPassword.trim(),
					verify: this.verify.trim()
				}
				resetPassword(params).then(res => {
					if (res.code === 0) {
						this.$message
							.success('重置成功')
							.then(this.$router.push({ name: 'OfficialLogin' }))
					}
				})
			}
		}
	}
}
</script>

<style lang="less">
.register_form {
	.ant-input {
		height: 44px !important;
		margin-bottom: 26px;
	}

	.ant-input-affix-wrapper {
		height: 44px !important;
	}
}

.ant-message-warning .anticon {
	color: #e43937;
}
</style>

<style scoped lang="less">
.slogan_tit_1 {
	font-weight: 500;
	font-size: 30px;
	color: #333333;
	text-align: center;
}

.register_page {
	width: 100%;
	height: 100%;
	background: #f5f5f5;
}

.register_form {
	margin-top: 30px;

	.margin_b_44 {
		margin-bottom: 26px;
	}

	.flex {
		.ant-input {
			width: 250px;
			margin-bottom: 0;
		}

		.button {
			width: 190px;
			height: 44px;
			background: #ffffff;
			border: 1px solid #e43937;
			border-radius: 2px;
			font-weight: 500;
			font-size: 14px;
			color: #e43937;
			text-align: center;
			line-height: 44px;
			margin-left: 9px;
			cursor: pointer;
		}

		.button_disabled {
			width: 190px;
			height: 44px;
			background: #ffffff;
			border: 1px solid rgba(0, 0, 0, 0.15);
			border-radius: 2px;
			font-weight: 500;
			font-size: 14px;
			color: #999999;
			text-align: center;
			line-height: 44px;
			margin-left: 9px;
		}
	}

	.submit_btn {
		width: 100%;
		height: 52px;
		background: #e43937;
		border-radius: 2px;
		font-weight: 500;
		font-size: 18px;
		color: #ffffff;
		text-align: center;
		line-height: 52px;
		margin-top: 42px;
		cursor: pointer;
	}
}

.tips_box {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 13px;

	.tips_1 {
		color: #666666;
		text-align: center;
		font-weight: 500;
		font-size: 14px;
	}

	.tips_2 {
		color: #e43937;
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		margin-left: 10px;
		cursor: pointer;
	}
}

::v-deep .ant-checkbox-wrapper {
	margin-top: 21px;
	font-size: 14px;
	font-weight: 500;
}
</style>
